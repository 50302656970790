@import "~antd/dist/reset.css";

@import "./datatables.bundle.css";
@import "./style.bundle.css";
@import "./vendors.bundle.css";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

#root {
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
}

a {
  text-decoration: none !important;
}

.HeightStretch {
  height: 100%;
}

.WidthStretch {
  width: 100%;
  height: 100%;
}

.onHover {
  cursor: pointer;
}

.alignLeft {
  float: left;
}

.alignRight {
  float: right;
}

.textCentred {
  text-align: center;
}

.profileImage {
  width: 40px;
  height: 40px;
}

.profileImageBig {
  width: 100px;
  height: 100px;
}

.profileFullName {
  padding: 9px !important;
}

.valideBtn {
  margin-top: 10px;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.horizontalSpace {
  margin: 20px;
}

.btn-bg-login {
  background: #ff0000 !important;
}

.swal2-icon.swal2-warning {
  border-color: "#FFC70";
  color: "#FFC70";
}
.swal2-icon.swal2-error {
  border-color: #f1416c;
  color: #f1416c;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: rgba(241, 65, 108, 0.75);
}
.swal2-icon.swal2-success {
  border-color: #50cd89;
  color: #50cd89;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #50cd89;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(80, 205, 137, 0.3);
}
.swal2-icon.swal2-info {
  border-color: #7239ea;
  color: #7239ea;
}
.swal2-icon.swal2-question {
  border-color: #009ef7;
  color: #009ef7;
}

.swal2-popup .swal2-styled.swal2-confirm {
  color: #fff;
  background-color: #009ef7;
  border: 0;
  padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
}

.swal2-container.swal2-center > .swal2-popup {
  gap: 22px;
}

.sweet2-ant-button {
  margin-right: 1rem;
}

.dropdown-menu {
  transform: translate(-1px, 21px) !important;
}

.ourSmartfoliosTable .ant-pro-table-alert {
  display: none;
  width: 0px !important;
}

.ourSmartfoliosTable .ant-table-selection-column {
  width: 0px !important;
}

.ourSmartfoliosTable .ant-table-row:hover {
  cursor: pointer;
}

.liveInvestmentsTable .ant-pro-table-alert {
  display: none;
}

.liveInvestmentsTable .ant-table-row:hover {
  cursor: pointer;
}

.ant-card-body:has(.no-padding) {
  padding: 0;
}

.ant-card-body:has(.hide-refresh-button)
  .ant-pro-table-list-toolbar
  .ant-pro-table-list-toolbar-right
  > div:nth-child(2)
  > div
  > div:nth-child(1) {
  display: none;
}

.ant-card-body:has(.gray-background) .ant-table-row {
  background-color: #F7F8FA;
}

.transparent-row {
  background-color: #fafafa;
}

.f-antdTableHeaderStyle .ant-table-thead .ant-table-cell {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-table-summary {
  display: table-header-group;
}
